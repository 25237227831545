// 调用axios封装
import request from '@/axios/api'


// 获取二手车手续费表单数据
export const secoundCarXfdata = data => {
    return request({
        url: '/system/setting/rscsxf',
        data,
        method: 'get'
    })
}
// 设置二手车手续费
export const secoundCarXfxg = data => {
    return request({
        url: '/system/setting/used/car/free',
        data,
        method: 'post'
    })
}


