<template>
    <div>
        <!-- 下载模块 -->
        <div
            style="border:1px,solid,rgb(221,221,221);height:auto;background-color:rgb(245,250,254);margin-bottom:10px;">
            <!-- xzmb 指定下载模版 @export-requested子组件导出-->
            <xt-table-top :xzmb-id='templateId' @export-requested="handleExport"></xt-table-top>
        </div>
        <!-- table模块  ref表格数据-->
        <div>
            <xt-table ref="tableComponent"></xt-table>
        </div>




    </div>
</template>
<script>
// 引入table top 组件
import xtTableTop from '@/components/Public/Tabletop/index.vue'
// 引入table组件
import xtTable from '@/components/Public/Table/index.vue'
import * as XLSX from 'xlsx';  //导出类型
export default {
    components: {
        xtTable,
        xtTableTop
    },
    data() {
        return {
            templateId: '1', // 这个就是页面的ref 用来判定需要哪一个模版  
        }
    },
    methods: {
        // 导出xt-table中的table数据
        handleExport() {
            // 从TableComponent获取数据  
            const tableData = this.$refs.tableComponent.getTableData();
            const tableTop = this.$refs.tableComponent.getTableTop();
            // 将表头添加到数据之前  
            const dataWithHeaders = [tableTop, ...tableData];
            // 将数据转换为工作表  
            // const ws = XLSX.utils.json_to_sheet(tableData);

            const ws = XLSX.utils.json_to_sheet(dataWithHeaders.map((row, index) => {
                if (index === 0) {
                    // 对于表头行，我们创建一个新对象，其属性与表头对应  
                    return tableTop.reduce((obj, header, idx) => {
                        obj[header] = row[idx];
                        return obj;
                    }, {});
                }
                // 对于数据行，我们假设每个对象都有正确的键  
                return row;
            }));

            // 创建一个工作簿并添加工作表  
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            // 导出xlsx文件  
            XLSX.writeFile(wb, `表格数据_${this.templateId}.xlsx`);
        }
    },
}
</script>