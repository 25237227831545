<template>
  <div>
    <el-container>
      <!-- 标题头 -->
      <el-header>
        <div class="headerText">货运物流智慧管理系统</div>

        <div class="button-container">
          <div style="width: 40vw; margin-left: 160px">
            <button
                v-for="(top) in top_select"
                :key="top.displayOrder"
                class="menu-button"
                :class="{ 'is-active': activeButtonIndex === top.displayOrder }"
                @click="toggleActive(top.displayOrder, top)"
            >
              {{ top.name }}
            </button>
          </div>

          <div style="position: absolute; display: flex; right: 0px">
            <div style="line-height: 52px; color: #fff; margin-right: 15px">
              你好，{{ roleName }}{{ username }}
            </div>
            <div>
              <!-- <button
                class="menu-button"
                :class="{ 'is-active': activeButtonIndex === 7 }"
                title="网站首页"
                @click="toggleActive(7)"
              >
                <i class="el-icon-s-platform"></i>
              </button> -->
              <button
                  class="menu-button"
                  :class="{ 'is-active': activeButtonIndex === 8 }"
                  title="修改密码"
                  @click="xgmm"
              >
                <i class="el-icon-edit-outline"></i>修改密码
              </button>
              <!-- 修改密码 -->
              <el-dialog
                  title="修改密码"
                  :visible.sync="dialogXgmm"
                  width="38%"
              >
                <el-form :model="xgmm_form" :rules="xgmm_rules">
                  <el-form-item label="旧密码" prop="oldpassword">
                    <el-input
                        v-model="xgmm_form.oldpassword"
                        placeholder="请输入旧密码"
                        autocomplete="off"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="新密码" prop="newpassword">
                    <el-input
                        v-model="xgmm_form.newpassword"
                        placeholder="请输入新密码"
                        autocomplete="off"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="确认密码" prop="secoundpassword">
                    <el-input
                        v-model="xgmm_form.secoundpassword"
                        placeholder="请确认密码"
                        autocomplete="off"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="xgmmSubmit"
                  >确 定
                  </el-button
                  >
                </div>
              </el-dialog>
              <button
                  class="menu-button"
                  :class="{ 'is-active': activeButtonIndex === 6 }"
                  title="退出登录"
                  @click="tcdl"
              >
                <i class="el-icon-user-solid"></i>退出登录
              </button>
            </div>
          </div>
        </div>
      </el-header>
      <el-container>
        <!-- 侧边栏  仅在预审管理和运输管理中展示-->
        <el-aside width="202px" v-show="activeButtonIndex==1 || activeButtonIndex==2 || activeButtonIndex==6">
          <el-menu
              :unique-opened="true"
              class="el-menu-vertical-demo"
              v-if="!buttonIndex"
          >
            <el-submenu
                v-for="(n) in the_select[0].menuConfigVOList"
                :index="`submenu-${n.id}`"
                :key="n.displayOrder"
            >
              <template #title>
                <span
                    slot="title"
                    style="width: 100%; height: 100%"
                    @click="getOneref(n.displayOrder)"
                >

                  {{ n.name }}</span
                >
              </template>
              <el-menu-item-group v-if="n.name!=='货运险' || activeButtonIndex==2 || activeButtonIndex==6">
                <el-menu-item
                    v-for="(sb) in n.functionConfigVOList"
                    :key="sb.displayOrder"
                    @click="PathJump(sb.url, sb, n)"
                    :index="`item-${n.id}-${sb.id}`"
                >{{ sb.name }}
                </el-menu-item
                >
              </el-menu-item-group>
              <el-menu-item-group v-if="n.name==='货运险' && activeButtonIndex==1">
                <el-submenu  v-for="(sb) in n.functionConfigVOList"
                    :key="sb.displayOrder"
                    :index="`item-${n.id}-${sb.id}`">
      <span slot="title">{{ sb.name }}</span>
      <el-menu-item
                    v-for="(sbs) in sb.subFunctionVOList"
                    :key="sbs.displayOrder"
                    @click="PathJump(sbs.url, sbs, n)"
                    :index="`item-${n.id}-${sb.id}-${sbs.id}`"
                >{{ sbs.name }}
                </el-menu-item
                >
    </el-submenu>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
          <!-- 系统设置 -->
          <el-menu
              default-active="1-4-2"
              class="el-menu-vertical-demo"
              :unique-opened="true"
              v-else
              router
          >
            <el-menu-item
                v-for="(sb, index) in the_select_one"
                :key="sb.displayOrder"
                @click="PathJump(sb.url, sb)"
                :index="`item--${sb.displayOrder}`"
            >{{ sb.name }}
            </el-menu-item
            >
          </el-menu>
        </el-aside>
        <el-main>
          <!-- 面包屑 -->
          <div class="breadcrumb-container">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <!-- <el-breadcrumb-item :to="{ path: '/' }">
                <i class="el-icon-s-platform"></i>首页</el-breadcrumb-item
              > -->
              <el-breadcrumb-item v-if="oneRefid">{{
                  oneRefid
                }}
              </el-breadcrumb-item>
              <el-breadcrumb-item v-if="twoRefid">{{
                  twoRefid
                }}
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="body-table-tzgl">
            <!-- 运单管理 -->
            <div class="body-table">
              <router-view :key="$route.fullPath"></router-view>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
// 退登接口
import {UserLoginOut} from "@/axios/Login/index.js";
// 修改密码
import {UpdataUserData} from "@/axios/Xtsz/Ywgl/yhgl.js"; //接口
export default {
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.xgmm_form.newpassword !== value) {
        callback(new Error("两次输入的密码不一致11111"));
      } else {
        callback();
      }
    };
    return {
      dialogXgmm: false, //修改密码弹框 默认隐藏false
      user_select: [], //用户列表栏
      top_select: [], //顶部菜单栏
      the_select: [], // 当前展示菜单
      lpgl_select: [], //理赔管理
      the_select_one: [],
      xtsz_select: [], //系统设置

      sbysgl_select: [],
      ztfx_select: [],
      ycgl_select: [],
      xgmm_form: {
        //修改密码表单
        oldpassword: "",
        newpassword: "",
        secoundpassword: "",
      },
      // 修改密码校验
      xgmm_rules: {
        oldpassword: [
          {required: true, message: "旧密码不能为空", trigger: "blur"},
        ],
        newpassword: [
          {required: true, message: "新密码不能为空", trigger: "blur"},
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        secoundpassword: [
          {required: true, message: "确认密码不能为空", trigger: "blur"},
          {required: true, validator: equalToPassword, trigger: "blur"},
        ],
      },
      activeButtonIndex: 1, //默认展示【系统设置】
      buttonIndex: false, //默认展示【系统设置】true展示系统设置；false展示申报平台
      oneRefid: "",
      twoRefid: "",
      roleName: JSON.parse(localStorage.getItem("roleName")),
      username: JSON.parse(localStorage.getItem("username"))
    };
  },
  methods: {
    // 点击切换高亮button
    toggleActive(index, top) {


      if (top) {
        this.oneRefid = top.name;
        this.twoRefid = "";
        localStorage.setItem("ywlxmc",JSON.stringify(top.name));
      }
      // 如果当前已经是这个按钮被选中，则取消选中
      //   // 否则，设置这个按钮为选中状态
      this.activeButtonIndex = index;
      // }
      if (index != 6) {
        this.the_select_one = [];
      }
      if (index == 1) {
        this.the_select = this.sbysgl_select;
      }
      if (index == 6) {
        // 展示 【系统设置】菜单
        this.buttonIndex = true;
        this.the_select_one = this.xtsz_select[0].menuConfigVOList
      } else {
        // 展示【申报平台】菜单
        this.buttonIndex = false;
      }
      if (index == 2) {
        this.the_select = this.lpgl_select;

      }
      if (index == 4) {
        if (this.$route.path !== "/tms/analyse") {
          this.$router.push("/tms/analyse");
        }
      }
      // 预存管理
      if (index == 3) {
        if (this.$route.path !== "/tms/presave") {
          this.$router.push("/tms/presave");
        }
      }
      // 驾驶舱
      if (index == 4) {
        if (this.$route.path !== "/tms/cockpit") {
          this.$router.push("/tms/cockpit");
        }
      }
      // oa
      if (index == 5) {
        // 截流
        if (this.$route.path !== "/Oa") {
          this.$router.push("/Oa");
        }
      }
    },
    getOneref(n) {

      this.oneRefid = n.name;
      this.twoRefid = "";
    },
    // 退出登录
    tcdl() {
      UserLoginOut({
        username: JSON.parse(localStorage.getItem("username")),
      }).then((res) => {
        console.log(res, 1111111);

        if (res.code == 200) {
          this.$message.success(res.message);
          this.$router.push("/login");
        }
      });
    },
    // 修改密码
    xgmm() {
      this.dialogXgmm = true;
    }, // 验证两次输入密码是否一致
    equalToPassword(rule, value, callback) {
      if (this.xgmm_form.newpassword !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    },
    xgmmSubmit() {
      const id = JSON.parse(localStorage.getItem("uId"));
      const roleName = JSON.parse(localStorage.getItem("roleName"));
      if (this.xgmm_form.secoundpassword != this.xgmm_form.newpassword) {
        this.$message.error("两次输入的密码不一致;请重新确定");
        return;
      } else {
        const data = {
          id: id,
          oldpassword: this.xgmm_form.oldpassword,
          newPassword: this.xgmm_form.secoundpassword,
          roleName: roleName,
        };

        UpdataUserData(data).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.message + "即将退出登录");
            this.dialogXgmm = false;
            setTimeout(() => {
              this.tcdl();
            }, 2000);
          }
        });
      }
    },

    // 页面跳转
    PathJump(url, sb, n) {
      if (
          sb.name == "申报入口" ||
          sb.name == "台账管理"
      ) {
      // 用来判断是否是货运险的-新车-二手车
        localStorage.setItem("insTypefuncId", JSON.stringify(sb.funcId));
        localStorage.setItem("insType", JSON.stringify(sb.name));
        localStorage.setItem("ListName", JSON.stringify(n.name));
        if (sb.name == "申报入口") {
          // 避免重复跳转
          if (this.$route.path !== url) {
            this.$router.push(url);
          } else {
            this.$router.replace({
              path: this.$route.path,
              query: {t: Date.now()} // 添加一个时间戳或随机数来避免缓存
            });
          }
        }
        if (sb.name == "台账管理") {
          // 避免重复跳转
          if (this.$route.path !== url + "/tzgl") {
            this.$router.push(url + "/tzgl");
          } else {
            this.$router.push(url);
          }
        }
      } else {
        this.$router.push(url);
      }
      if (n) {
        this.oneRefid = n.name;
        this.twoRefid = sb.name;
      } else {
        this.oneRefid = sb.name;
      }

    },
  },
  created() {
    this.xgmm_rules.secoundpassword[1].validator = this.equalToPassword;
    this.user_select = JSON.parse(localStorage.getItem("UserYwxx"));
    const seen = new Set();
    this.top_select = this.user_select.filter((item) => {
      // 如果id尚未在seen中，则添加到seen并返回true
      return !seen.has(item.name) && seen.add(item.name);
    });

    // 现在对 top_select 根据 displayOrder 进行排序
    this.top_select.sort((a, b) => a.displayOrder - b.displayOrder);
    this.sbysgl_select = this.user_select.filter(
        (item) => item.name === "运输管理"
    );

    this.lpgl_select = this.user_select.filter(
        (item) => item.name === "理赔管理"
    );
    this.xtsz_select = this.user_select.filter(
        (item) => item.name === "系统设置"
    );
    this.the_select = this.sbysgl_select;
  },
};
</script>
<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

//头部 导航栏
.el-header {
  // background: url(@/assets/bj.jpg) no-repeat;
  height: 52px !important;
  float: right;
  text-align: center;
  width: 100%;
  padding: 0;
  background-color: rgba(39, 43, 48, 1);

  .headerText {
    position: absolute;
    width: 10vw;
    left: 12px;
    top: 9px;
    height: 34px;
    opacity: 1;
    font-size: 1.9vh;
    font-weight: 900;
    letter-spacing: 0px;
    line-height: 33.6px;
    color: rgb(221, 221, 221);
    text-align: left;
    vertical-align: top;
  }

  //头部菜单 菜单按钮栏
  .button-container {
    width: 83.45%;
    display: flex;
    /* 使用flex布局使按钮紧密排列 */
    align-items: stretch;
    /* 使按钮高度充满父容器 */
    height: 100%;
    /* 假设这是您想要的父容器高度，或者您可以设置为固定值 */
    padding: 0;
  }

  // 头部菜单 按钮
  .menu-button {
    flex: 1;
    /* 使所有按钮宽度等分 */
    height: 99%;
    font-size: 0.85vw;
    width: 5vw;
    background-color: rgba(39, 43, 48, 1);
    /* 按钮高度占满容器 */
    border: none;
    padding: 0px !important;
    cursor: pointer;
    color: #fff;
    /* 鼠标划过时显示手形光标 */

    /* 添加右侧分隔线，最后一个按钮需要特别处理 */

    :last-child {
      border-right: none;
      /* 移除最后一个按钮的右侧分隔线 */
    }

    &:hover {
      // background-color: #bbb;
      background-color: rgba(73, 78, 83, 1);
      color: white;
      /* 划过时的高亮效果 */
    }

    &:active {
      background-color: rgba(73, 78, 83, 1) !important;
      /* 选中时的高亮效果，需要JavaScript或Vue来添加这个class */
      color: white;
      /* 文本颜色可能需要调整以适应背景色 */
    }
  }

  // 头部菜单 按钮选中高亮
  .is-active {
    background-color: rgba(73, 78, 83, 1) !important;
    /* 选中时的高亮效果，需要JavaScript或Vue来添加这个class */
    color: white;
    /* 文本颜色可能需要调整以适应背景色 */
  }
}

//body
.el-container {
  display: flex;

  // 侧边栏
  .el-aside {
    height: 94vh;
    background-color: #fff;
    width: 202px;

    // 侧边栏 导航
    .el-menu {
      background-color: #fff;
      height: 94vh;
      width: 200px;
    }
  }

  // 主体
  .el-main {
    height: 94vh;
    width: 70vw;
    background-color: #fff;
    padding: 0px;
  }

  .breadcrumb-container {
    width: 100%;
    height: 34px;
    background-color: #eee;
    line-height: 34px;

    // 面包屑路由
    .el-breadcrumb {
      position: absolute;
      margin-bottom: 0px;
      margin-top: 8px;
      margin-left: 15px;
    }
  }
}

.body-table-tzgl {
  width: 100%;
  height: 95.5%;
  overflow-y: auto;

  .body-table {
    width: 98%;
    margin-left: 1%;
    margin-top: 14px;
    height: 100%;
  }
}

/* 整个滚动条 */

.body-table-tzgl::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: rgba(70, 166, 255, 0.1);
}

/* 滚动条上的按钮（上下箭头） */
::-webkit-scrollbar-button {
  display: none;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  display: none;
  background-color: rgba(70, 166, 255, 0.1);
}

/*  滚动条没有滑块的轨道部分. */
::-webkit-scrollbar-track-piece {
  background-color: rgba(228, 228, 228, 0.8);
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
  background-color: rgba(80, 80, 80, 1);
  background-clip: padding-box;
  border: 2px solid transparent;
  // border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
