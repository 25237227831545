<template>
  <div>
    <div>
      <!-- 查询栏 -->
      <div class="cxl">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="角色" class="custom-form-item">
            <el-select
              v-model="formInline.region"
              placeholder="请选择用户角色"
              class="cxl-input"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="cxl-form-btns">
            <el-button class="cxl-form-btn" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- table模块  ref表格数据-->
      <div style="margin-top: 20px">
        <el-table
          ref="multipleTable"
          :data="tableData"
          border
          style="width: 100%"
          :header-cell-style="{ background: 'rgb(245,250,254)', color: '#000' }"
        >
          <el-table-column
            align="center"
            header-align="center"
            label="ID"
            prop="id"
          >
          </el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            label="角色名称"
            prop="jsmc"
          >
          </el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            label="状态"
            prop="zt"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.zt === '100' ? '开启' : '关闭'"
                placement="top"
              >
                <el-switch
                  v-model="scope.row.zt"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-value="100"
                  inactive-value="0"
                >
                </el-switch>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页器 -->
        <div v-if="tableData.length" style="text-align: left; margin-top: 5px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 引入table top 组件
import yhglTableTop from "@/components/Public/Tabletop/yhglForm.vue";
// 引入table组件
import xtTable from "@/components/Public/Table/index.vue";
import * as XLSX from "xlsx"; //导出类型
export default {
  components: {
    xtTable,
    yhglTableTop,
  },
  data() {
    return {
      templateId: "1", // 这个就是页面的ref 用来判定需要哪一个模版
      tableTop: [
        {
          label: "ID",
          value: "ID",
        },
        {
          label: "姓名",
          value: "xm",
        },
        {
          label: "性别",
          value: "xb",
        },
        {
          label: "证件类型",
          value: "zjlx",
        },
        {
          label: "证件号码",
          value: "zjhm",
        },
        {
          label: "职务",
          value: "zw",
        },
        {
          label: "健康状况",
          value: "jkzk",
        },
        {
          label: "工资",
          value: "gz",
        },
        {
          label: "工作单位",
          value: "gzdw",
        },
        {
          label: "保额",
          value: "be",
        },
        {
          label: "起保日期",
          value: "qbrq",
        },
        {
          label: "保险费",
          value: "bxf",
        },
        {
          label: "手续费",
          value: "sxf",
        },
        {
          label: "状态",
          value: "zt",
        },
        {
          label: "创建时间",
          value: "cjsj",
        },
      ], // 表格头
      tableData: [
        {
          ID: "221",
          xm: "张三",
          xb: "男",
          zjlx: "身份证",
          zjhm: "220111202101012222",
          zw: "团体意外",
          jkzk: "健康",
          gz: "10000.00",
          gzdw: "四扬物流",
          be: "8003.00",
          qbrq: "2020-01-01",
          bxf: "5005.00",
          sxf: "750.75",
          zt: "100",
          cjsj: "2021-07-12 23:12:08",
        },
        {
          ID: "221",
          xm: "通顺达",
          xb: "男",
          zjlx: "身份证",
          zjhm: "	230904198903191316",
          zw: "内勤",
          jkzk: "健康",
          gz: "7000.00",
          gzdw: "四扬物流",
          be: "3000.00",
          qbrq: "2021-04-15",
          bxf: "337.01",
          sxf: "750.75",
          zt: "0",
          cjsj: "	2021-05-07 17:33:57",
        },
        {
          ID: "221",
          xm: "张三",
          xb: "男",
          zjlx: "身份证",
          zjhm: "220111202101012222",
          zw: "团体意外",
          jkzk: "一般",
          gz: "10000.00",
          gzdw: "新悦物流",
          be: "3000.00",
          qbrq: "2020-01-01",
          bxf: "5005.00",
          sxf: "337.01",
          zt: "100",
          cjsj: "2021-07-12 23:12:08",
        },
      ], //表格数据
      formInline: {
        user: "",
        region: "",
      }, //查询项
      currentPage: 1,
      PageSize: 10,
      pageSizes: [10, 20, 30, 40],
    };
  },
  methods: {
    handleSizeChange(val) {
      this.PageSize = val;
      this.$emit("PageSize", val);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.$emit("currentPage", val);
    },
    onSubmit() {
      console.log(111);
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
// 查询栏
.cxl {
  border: 1px solid rgb(221, 221, 221);
  position: relative;
  padding: 10px;
  padding-bottom: 0px;

  // input输入框 label
  /deep/ .custom-form-item .el-form-item__label {
    font-size: 12px !important;
    /* 修改字体大小 */
    width: 72px;
    /* 限制宽度 */
    overflow: hidden;
    /* 如果需要，可以添加溢出隐藏 */
    text-overflow: ellipsis;
    /* 文本溢出时显示省略号 */
    white-space: nowrap;
    /* 保持文本在一行内 */
  }

  // input输入框
  .cxl-input {
    width: 180px;
    font-size: 12px;
    height: 30px !important;
    /* 使用 !important 确保覆盖默认样式 */
  }

  // 修改input高度
  /deep/ .el-input__inner {
    height: 26px !important;
  }

  // 修改el-form-item行之间间距
  .el-form-item {
    margin-bottom: 10px !important;
  }

  // 查询项 按钮栏
  .cxl-form-btns {
    position: absolute;
    bottom: 0px;
    right: 30px;

    // 按钮
    .cxl-form-btn {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .cxl-form-btn:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}
</style>
