<template>
  <div>
    <el-checkbox
      :indeterminate="isIndeterminate"
      v-model="localCheckAll"
      @change="handleCheckAllChange"
      >全选</el-checkbox
    >
    <div style="margin: 15px 0"></div>
    <el-checkbox-group
      v-model="localCheckedCities"
      @change="handleCheckedCitiesChange"
    >
      <!--  -->
      <!-- 提示异常就删除 :checked="checkedCityIds.includes(city.id)" -->
      <el-checkbox
        v-for="city in cities"
        :label="city.id"
        :key="city.id"
        :class="{ 'is-assigned': city.isAssigned }"
        >{{ city.permissionName }}</el-checkbox
      >
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  props: {
    cities: Array,
    value: Array, // 使用v-model时，props中需要有一个名为value的prop
  },
  data() {
    return {
      localCheckAll: false,
      isIndeterminate: false,
      localCheckedCities: [],
    };
  },
  computed: {
    // isIndeterminate() {
    //   const checkedCount = this.localCheckedCities.length;
    //   return checkedCount > 0 && checkedCount < this.cities.length;
    // },
  },
  watch: {
    // 监听localCheckedCities变化，并通知父组件
    localCheckedCities(newVal) {
      this.$emit("input", newVal);
    },
    // 监听父组件传入的value变化，并更新localCheckedCities
    value(newVal) {
      this.localCheckedCities = newVal;
    },
  },
  created() {
    // 根据cities中的isAssigned初始化localCheckedCities
    this.localCheckedCities = this.cities
      .filter((city) => city.isAssigned == true)
      .map((city) => city.id);
  },
  methods: {
    handleCheckAllChange(val) {

      this.localCheckedCities = val ? this.cities.map((city) => city.id) : [];
      this.isIndeterminate = false; // 改变全选状态
      this.localCheckAll = val;
      this.$emit("input", this.localCheckedCities); // 通知父组件
    },
    handleCheckedCitiesChange(value) {
      const selectedCities = this.cities.filter((city) =>
        value.includes(city.id)
      );

      // 现在selectedCities包含了所有被选中的城市对象
      this.localCheckAll = value.length === this.cities.length;
      this.isIndeterminate =
        value.length > 0 && value.length < this.cities.length; // 改变全选状态
      this.$emit("input", value); // 通知父组件
    },
  },
  mounted() {
  },
};
</script>
