// 调用axios封装
import request from '@/axios/api'


// 初始化与用户管理
export const UserData = data => {
    return request({
        url: '/tms/all/user',
        data,
        method: 'post'
    })
}
// 编辑 展示用户信息
export const OpenUserData = publishActivity => {
    const data = publishActivity
    return request({
        url: '/tms/find/user/byId',
        data,
        method: 'post'
    })
}
// 用户编辑 保存
export const UpdataUserData = publishActivity => {
    const data = publishActivity
    return request({
        url: '/tms/update/user',
        data,
        method: 'post'
    })
}
// 用户添加 
export const SaveUserData = publishActivity => {
    const data = publishActivity
    return request({
        url: '/tms/save/user',
        data,
        method: 'post'
    })
}
// 根据角色名称查询用户信息
export const UserDataById = publishActivity => {
    const data = publishActivity
    return request({
        url: '/tms/find/user/byRoleName',
        data,
        method: 'post'
    })
}
// 删除  
export const DeleteUserId = publishActivity => {
    const data = publishActivity
    return request({
        url: '/tms/delete/user/byId',
        data,
        method: 'post'
    })
}
// 添加用户选择用户类型。返回固定角色
export const GetUserType = publishActivity => {
    const data = publishActivity
    return request({
        url: '/system/find/role/byUserType',
        data,
        method: 'post'
    })
}