// 调用axios封装
import request from '@/axios/api'


// 获取商品车保费设置表单数据

export const newCardata = data => {
    return request({
        url: '/system/setting/spcbf',
        data,
        method: 'post'
    })
}
// 设置商品车保费
export const newCarxg = data => {
    return request({
        url: '/system/setting/product/vehicle',
        data,
        method: 'post'
    })
}
// 删除商品车接口
export const deleteCarxg = data => {
    return request({
        url: '/system/delete/product/vehicle',
        data,
        method: 'post'
    })
}
// 下载
export const downLoad = data => {
    return request({
        responseType: "blob",
        url: '/download/template',
        data,
        method: 'post'
    })
}