<template>
  <!--     :size="size" -->
  <el-descriptions
    class="margin-top"
    title="二手车手续费设置"
    :column="1"
    border
  >
    <template slot="extra">
      <el-button type="primary" size="small" @click="qrtj">确认提交</el-button>
    </template>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>
        货运险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[3].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[3].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-mobile-phone"></i>
        商业险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[0].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[0].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-location-outline"></i>
        交强险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[2].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[2].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-tickets"></i>
        员工雇主险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[5].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[5].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-location-outline"></i>
        车辆雇主险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[1].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[1].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-location-outline"></i>
        财产险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[4].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[4].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-location-outline"></i>
        货运三者险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[6].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[6].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>
  </el-descriptions>
</template>

<script>
import {
  secoundCarXfdata,
  secoundCarXfxg,
} from "@/axios/Xtsz/Xtsz/escsxfsz.js";
export default {
  data() {
    return {
      dataArray: [
        { feeRate: null, managementCost: null },
        { feeRate: null, managementCost: null },
        { feeRate: null, managementCost: null },
        { feeRate: null, managementCost: null },
        { feeRate: null, managementCost: null },
        { feeRate: null, managementCost: null },
        { feeRate: null, managementCost: null },
      ],
    };
  },
  methods: {
    // 初始化加载获取手续费设置
    getSxfData() {
      secoundCarXfdata().then((res) => {
        console.log(res.data, "获取手续费设置");
        this.dataArray = res.data;
        console.log(this.dataArray, "获取手续费设置");
      });
    },
    // 提交
    qrtj() {
      const extractedData = this.dataArray.map((policy) => ({
        feeRate: policy.feeRate,
        managementCost: policy.managementCost,
        insType: policy.insType,
      }));
      secoundCarXfxg({ freeRequestList: extractedData }).then((res) => {
        console.log(res, "修改手续费设置");
        this.$message.success("修改成功");
      });
    },
  },
  mounted() {
    // 初始化加载获取手续费设置
    this.getSxfData();
  },
};
</script>

<style scoped>
/* 根据需要添加样式 */
</style>
