// 调用axios封装
import request from '@/axios/api'


// @@@@@申报管理
// 责意险
export const downListSbYggzzrx = data => {
    return request({
        url: '/export/decl/liabilityInsData',
        data,
        method: 'get'
    })
}
// 车辆雇主责任险
export const downListSbClgzzrx = data => {
    return request({
        url: '/export/decl/vehicleEmpLiabilityInsData',
        data,
        method: 'get',
        headers: {'Content-Type': 'application/join'}, 
      	responseType: 'blob' // 注意不是在headers里面
    })
}
// 货运三者险
export const downListSbHyszx = data => {
    return request({
        url: '/export/decl/cargo3LiabilityInsData',
        data,
        method: 'get'
    })
}
// 商业险
export const downListSbSyx = data => {
    return request({
        url: '/export/decl/propertyIns',
        data,
        method: 'get'
    })
}
// 交强险
export const downListSbJqx = data => {
    return request({
        url: '/export/decl/compulsoryTrafficInsData',
        data,
        method: 'get'
    })
}
// 财产险
export const downListSbCcx = data => {
    return request({
        url: '/export/decl/commercialInsData',
        data,
        method: 'get'
    })
}
// 货运险
export const downListSbHyx = data => {
    return request({
        url: '/export/decl/CargoInsData',
        data,
        method: 'get'
    })
}

//@ 导出 运输管理
//  商业险
export const downloadExporstSyx = data => {
    return request({
        responseType: "blob",
        url: '/export/decl/propertyIns',
        data,
        method: 'post'
    })
}
//  交强险
export const downloadExporstJqx = data => {
    return request({
        responseType: "blob",
        url: '/export/decl/compulsoryTrafficInsData',
        data,
        method: 'post'
    })
}
// 责意险
export const downloadExporstYggzzrx = data => {
    return request({
        responseType: "blob",
        url: '/export/decl/liabilityInsData',
        data,
        method: 'post'
    })
}
// 车辆雇主责任险
export const downloadExporstClgzzrx = data => {
    return request({
        responseType: "blob",
        url: '/export/decl/vehicleEmpLiabilityInsData',
        data,
        method: 'post'
    })
}
// 货运三者险
export const downloadExporstHyszx = data => {
    return request({
        responseType: "blob",
        url: '/export/decl/cargo3LiabilityInsData',
        data,
        method: 'post'
    })
}
// 财产险
export const downloadExporstCcx = data => {
    return request({
        responseType: "blob",
        url: '/export/decl/commercialInsData',
        data,
        method: 'post'
    })
}
// 货运险 新车
export const downloadExporstHyxxc = data => {
    return request({
        responseType: "blob",
        url: '/export/decl/newCarsCargoInsData',
        data,
        method: 'post'
    })
}
// 货运险 二手车
export const downloadExporstHyxesc = data => {
    return request({
        responseType: "blob",
        url: '/export/decl/usedCarsCargoInsData',
        data,
        method: 'post'
    })
}
// 导出 理赔管理 货运三者险
export const downloadExporstLpHyszx = data => {
    return request({
        responseType: "blob",
        url: '/export/claims/cargo3LiabilityInsData',
        data,
        method: 'post'
    })
}
// 货运险
export const downloadExporstLpHyx = data => {
    return request({
        responseType: "blob",
        url: '/export/claims/cargoInsClaimsData',
        data,
        method: 'post'
    })
}
// 财产险
export const downloadExporstLpCcx = data => {
    return request({
        responseType: "blob",
        url: '/export/claims/commercialInsClaimsData',
        data,
        method: 'post'
    })
}
// 交强险
export const downloadExporstLpJqx = data => {
    return request({
        responseType: "blob",
        url: '/export/claims/compulsoryTrafficInsClaimsData',
        data,
        method: 'post'
    })
}
// 员工雇主责任险
export const downloadExporstLpYggzzrx = data => {
    return request({
        responseType: "blob",
        url: '/export/claims/empLiabilityInsClaimsData',
        data,
        method: 'post'
    })
}
// 车辆雇主责任险
export const downloadExporstLpClgzzrx = data => {
    return request({
        responseType: "blob",
        url: '/export/claims/vehicleEmpLiabilityInsClaimsData',
        data,
        method: 'post'
    })
}
// 商业险
export const downloadExporstLpSyx = data => {
    return request({
        responseType: "blob",
        url: '/export/claims/propertyInsClaimsData',
        data,
        method: 'post'
    })
}


