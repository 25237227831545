<template>
  <!-- table top 组件 -->
  <!-- xzmb 指定下载模版 @export-requested子组件导出-->
  <!-- <xt-table-top
            :xzmb-id="templateId"
            @export-requested="handleExport"
            :Usersbxx="Usersbxx"
          ></xt-table-top> -->
  <div>
    <!-- 下载栏 -->
    <div class="xzl">
      <div class="xzl-xzmb" @click="downloadTemplate">下载模版</div>
      <div class="xzl-div-btn">
        <el-button class="xzl-div-btns" @click="tjfj">
          <i style="font-size: 20px" class="el-icon-upload"></i>上传excel
        </el-button>
        <!-- 上传excel -->
        <el-dialog
          title="上传excel"
          :visible.sync="tjfjVisble"
          :close-on-click-modal="false"
        >
          <div style="display: flex; justify-content: center">
            <el-upload
              style="width: auto"
              class="centerImg"
              :file-list="fileList"
              ref="uploadicon"
              :action="''"
              :on-remove="handleRemove"
              :auto-upload="false"
              :on-change="handleAvatarChangeIcon"
              accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              multiple
              drag
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传xlsx文件，且不超过1MB
              </div>
            </el-upload>
          </div>
          <el-button
            class="xzl-div-btns"
            style="background-color: rgba(231, 102, 109); margin-left: 65%"
            >导入</el-button
          >
        </el-dialog>
      </div>
    </div>
    <!-- 查询栏 -->
    <div class="cxl">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="状态" class="custom-form-item">
          <el-select
            v-model="formInline.region"
            placeholder="状态"
            class="cxl-input"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="雇员姓名" class="custom-form-item">
          <el-input
            v-model="formInline.user"
            placeholder="请输入雇员姓名"
            class="cxl-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="证件号码" class="custom-form-item">
          <el-input
            v-model="formInline.user"
            placeholder="请输入证件号码"
            class="cxl-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="工作单位" class="custom-form-item">
          <el-input
            v-model="formInline.user"
            placeholder="请输入工作单位"
            class="cxl-input"
          ></el-input>
        </el-form-item>
        <el-form-item class="cxl-form-btns">
          <el-button class="cxl-form-btn" @click="onSubmit">查询</el-button>
          <el-button
            class="cxl-form-btn"
            style="background-color: rgba(231, 102, 109)"
            @click="exportToExcel"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 金额显示栏 -->
    <div
      style="
        height: 35px;
        line-height: 35px;
        font-size: 12px;
        font-weight: 900;
        padding-left: 25px;
      "
    >
      总申报数量 {{ this.Usersbxx.zsbsl }}；保费合计：人民币{{
        this.Usersbxx.bfhj
      }}元；手续费合计{{ this.Usersbxx.sxfhj }}元
    </div>
  </div>
</template>
<script>
// 下载模版 xlsx
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
// 上传附件
import { read, utils } from "xlsx";
export default {
  props: {
    // 指定下载模版的id
    xzmbId: {
      type: String,
      required: true,
    },
    Usersbxx: {
      type: Object,
      required: true,
    },
    // 可以根据需要添加其他props，如数据等
  },
  data() {
    return {
      fileList: [], //上传文件列表
      tableHead: [], //表头
      tableData: [], // 表数据
      formInline: {
        user: "",
        region: "",
      },
      tjfjVisble: false, //添加附件
      iconformData: [{ url: "", name: "" }],
    };
  },
  watch: {
    // 监听 关闭就清空已上传文件
    tjfjVisble(newVal) {
      if (newVal === false) {
        //关闭附件详情页面
        this.fileList = [];
      }
    },
  },
  methods: {
    onSubmit() {
      // 查询发送请求参数给父组件
      this.$emit("onQuery", this.formInline);
    },
    // 下载xlsx 模版
    downloadTemplate() {
      // 根据templateId或其他props生成Excel数据
      // 这里只是一个示例，实际情况中你可能需要根据templateId从API获取数据
      const ws_data = [
        ["ID", "Name", "Age"],
        [1, "Alice", 25],
        [2, "Bob", 24],
      ];

      // 假设这里的数据是根据templateId动态生成的
      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Data");

      // 生成Excel文件
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      // 保存文件
      saveAs(
        new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
        `保险_${this.xzmbId}.xlsx`
      );
    },
    s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    },
    // 点击 上传附件
    tjfj() {
      this.tjfjVisble = true;
    },
    //文件列表移除文件时的钩子
    handleRemove() {
      this.iconformData = [];
    },
    // 操作添加附件【上传】按钮
    handleAvatarChangeIcon(file, fileList) {
      const isXLSX =
        file.raw.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const isXLS = file.raw.name.endsWith(".xls");
      const isCorrectFile = isXLSX || isXLS;
      const isLt2M = file.raw.size / 1024 / 1024 < 1;
      if (!isCorrectFile) {
        this.$message.error("上传excel上传xlsx/xls格式文件!");
        return false;
      } else if (!isLt2M) {
        this.$message.error("上传excel大小不能超过 1MB!");
        return false;
      } else if (isLt2M && isCorrectFile) {
        // this.iconformData.img = file.url; //图片的url
        this.iconformData.name = file.name; //图片的名字
      }
      // 具备fileList长度说明有附件上传   关闭添加附件页面显示附件修改页面
      this.fjxxSecoendData = fileList;
    },
    // 导出excel
    exportToExcel() {
      // 将导出请求发送到父组件
      this.$emit("export-requested");
    },
  },
};
</script>
<style lang="less" scoped>
// 下载栏
.xzl {
  border: 1px, solid, rgb(221, 221, 221);
  display: flex;

  // 下载模版
  .xzl-xzmb {
    font-size: 12px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(30, 159, 255);
    font-weight: 900;
    cursor: pointer;
    margin-right: 15px;
  }

  // 上传+导入
  .xzl-div-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;

    // 按钮公共样式
    .xzl-div-btns {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .xzl-div-btns:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}

// 查询栏
.cxl {
  border: 1px solid rgb(221, 221, 221);
  position: relative;
  padding: 10px;
  padding-bottom: 0px;

  // input输入框 label
  /deep/ .custom-form-item .el-form-item__label {
    font-size: 12px !important;
    /* 修改字体大小 */
    width: 72px;
    /* 限制宽度 */
    overflow: hidden;
    /* 如果需要，可以添加溢出隐藏 */
    text-overflow: ellipsis;
    /* 文本溢出时显示省略号 */
    white-space: nowrap;
    /* 保持文本在一行内 */
  }

  // input输入框
  .cxl-input {
    width: 180px;
    font-size: 12px;
    height: 30px !important;
    /* 使用 !important 确保覆盖默认样式 */
  }

  // 修改input高度
  /deep/ .el-input__inner {
    height: 26px !important;
  }

  // 修改el-form-item行之间间距
  .el-form-item {
    margin-bottom: 10px !important;
  }

  // 查询项 按钮栏
  .cxl-form-btns {
    position: absolute;
    bottom: 0px;
    right: 30px;

    // 按钮
    .cxl-form-btn {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .cxl-form-btn:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}
</style>
