<template>
    <!-- table top 组件 -->
    <div>
        <!-- 下载栏 -->
        <div class='xzl'>
            <div class='xzl-div-btn'>
        <el-button class='xzl-div-btns' @click="tjfj">
          <i style="font-size: 14px;" class="el-icon-plus"></i>添加用户
        </el-button>
            </div>

        </div>
        <!-- 查询栏 -->
        <div class='cxl'>
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="角色" class="custom-form-item">
                    <el-select v-model="formInline.region" placeholder="请选择用户角色" class='cxl-input'>
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class='cxl-form-btns'>
                    <el-button class='cxl-form-btn' @click="onSubmit">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
// 下载模版 xlsx
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
// 上传附件 
import { read, utils } from "xlsx";
export default {
    props: {
        // 指定下载模版的id
        xzmbId: {
            type: String,
            required: true
        },
        Usersbxx: {
            type: Object,
            required: true
        }
        // 可以根据需要添加其他props，如数据等  
    },
    data() {
        return {
            tableHead: [], //表头
            tableData: [],// 表数据
            formInline: {
                user: '',
                region: ''
            },
            tjfjVisble: false, //添加附件
            iconformData: [{ url: "", name: "" }],
        }
    },
    watch: {

    },
    methods: {
        onSubmit() {
        },
        // 下载xlsx 模版
        downloadTemplate() {
            // 根据templateId或其他props生成Excel数据  
            // 这里只是一个示例，实际情况中你可能需要根据templateId从API获取数据  
            const ws_data = [
                ["ID", "Name", "Age"],
                [1, "Alice", 25],
                [2, "Bob", 24]
            ];

            // 假设这里的数据是根据templateId动态生成的  
            const ws = XLSX.utils.aoa_to_sheet(ws_data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Data");

            // 生成Excel文件  
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

            // 保存文件  
            saveAs(new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }), `保险_${this.xzmbId}.xlsx`);
        },
        s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) {
                view[i] = s.charCodeAt(i) & 0xFF;
            }
            return buf;
        },
        // 导出excel
        exportToExcel() {
            // 将导出请求发送到父组件  
            this.$emit('export-requested');
        }
    }
}
</script>
<style lang="less" scoped>
// 下载栏
.xzl {
    border: 1px, solid, rgb(221, 221, 221);
    display: flex;

  // 上传+导入
  .xzl-div-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;

    // 按钮公共样式
    .xzl-div-btns {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(231, 102, 109);
      margin-left:1vw;
    }

    .xzl-div-btns:hover {
      opacity: 0.8
        /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }

}

// 查询栏
.cxl {
    border: 1px solid rgb(221, 221, 221);
    position: relative;
    padding: 10px;
    padding-bottom: 0px;

    // input输入框 label
    /deep/ .custom-form-item .el-form-item__label {
        font-size: 12px !important;
        /* 修改字体大小 */
        width: 72px;
        /* 限制宽度 */
        overflow: hidden;
        /* 如果需要，可以添加溢出隐藏 */
        text-overflow: ellipsis;
        /* 文本溢出时显示省略号 */
        white-space: nowrap;
        /* 保持文本在一行内 */
    }

    ;

    // input输入框
    .cxl-input {
        width: 180px;
        font-size: 12px;
        height: 30px !important;
        /* 使用 !important 确保覆盖默认样式 */
    }

    // 修改input高度
    /deep/ .el-input__inner {
        height: 26px !important;
    }

    // 修改el-form-item行之间间距
    .el-form-item {
        margin-bottom: 10px !important;
    }

    // 查询项 按钮栏
    .cxl-form-btns {
        position: absolute;
        bottom: 0px;
        right: 30px;

        // 按钮
        .cxl-form-btn {
            height: 28px;
            line-height: 28px;
            padding: 0 10px;
            font-size: 12px;
            color: white;
            font-weight: 900;
            background-color: rgba(0, 150, 136);
        }

        .cxl-form-btn:hover {
            opacity: 0.8
                /* 举例使用 Element UI 的主题色作为文字颜色 */
        }
    }
}
</style>