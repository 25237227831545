// 调用axios封装
import request from '@/axios/api'


// 下载附件
export const download = data => {
    return request({
        responseType: "blob",
        url: '/download/template',
        data,
        method: 'post'
    })
}
// 上传文件
export const upload = data => {
    return request({
        url: '/upload/template',
        data,
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}




