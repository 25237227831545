<template>
  <div class="login_container">
    <!-- 登陆表单区域  -->
    <el-form
        id="login"
        ref="loginFormRef"
        :model="loginForm"
        label-width="0px"
        class="login_form"
        :rules="login_rules"
    >
      <div class="WelcomeLgn">货运物流智慧管理系统</div>
      <!-- 用户名 -->
      <el-form-item prop="loginName">
        <el-input
            class="UserName"
            placeholder="请输入用户名"
            v-model="loginForm.loginName"
            prefix-icon="iconfont icon-user"
            maxlength="20"
            show-word-limit
        ></el-input>
      </el-form-item>
      <!-- 密码 -->
      <el-form-item prop="passWord">
        <el-input
            type="password"
            class="PassWord"
            placeholder="请输入密码"
            v-model="loginForm.passWord"
            prefix-icon="iconfont icon-3702mima"
            :maxlength="20"
            show-word-limit
            inline-message="true"
            show-password
        >
        </el-input>
      </el-form-item>
      <!-- 验证码 -->
      <!-- <el-form-item prop="Yzm">
        <el-input
            class="yzm"
            placeholder="请输入验证码"
            v-model="loginForm.yzm"
            prefix-icon="iconfont icon-3702mima"
            :maxlength="4"
            show-word-limit
        >
        </el-input>
        <div class="captcha-container" @click="refreshCode">
          <s-identify :identify-code="identifyCode"></s-identify>
        </div>
      </el-form-item> -->

      <!-- 按钮区域 -->
      <el-form-item class="btns">
        <el-button class="btnsLogin" type="primary" @click="login"
        >登录
        </el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
// 引入验证码js
// import SIdentify from "@/components/Views/Yzm/index.vue";
// 退登录接口
import {UserLogin, UserLoginOut} from "@/axios/Login/index.js";

export default {
  // 注册
  // components: {
  //   SIdentify,
  // },
  data() {
    return {
      // 这是登录表单的数据绑定对象
      loginForm: {
        loginName: "",
        passWord: "",
        // yzm: "",
      },
      identifyCode: "", // 验证码
      identifyCodes:
          "abcdefghijklmnpqrstuvwxyz123456789", //验证码随机范围
      login_rules: {
        // 验证用户名是否合法
        loginName: [
          {required: true, message: "请输入用户名", trigger: "blur"},
          {min: 3, max: 20, message: "登录名长度为2-20位", trigger: "blur"},
        ],
        // 验证密码是否合法
        passWord: [{required: true, message: "请输入密码", trigger: "blur"}],
        // 验证码
        // Yzm: [{required: true, message: "请输入正确验证码", trigger: "blur"}],
      },
    };
  },
  methods: {
    // 登录
    login() {
      // console.log(this.loginForm.yzm, this.identifyCode);
      
      // if (this.loginForm.yzm == this.identifyCode) {
        UserLogin({
          username: this.loginForm.loginName,
          password: this.loginForm.passWord,
        }).then((res) => {

          if (res.code == 200) {
            localStorage.setItem("UserYwxx", JSON.stringify(res.data.menus.systemNavConfigVOList));
            localStorage.setItem("roleName", JSON.stringify(res.data.roleName));
            localStorage.setItem("username", JSON.stringify(res.data.username));
            localStorage.setItem("uId", JSON.stringify(res.data.uid));
            localStorage.setItem("roleId", JSON.stringify(res.data.roleId));
            localStorage.setItem("token", res.data.token);
            this.$message.success("登录成功");
            this.$router.push("/HomeView");
          } else {
            // this.$message.error(res.data.message);
          }
        });
      // } else {
      //   this.$message.error("验证码错误,请输入正确验证码");
      // }
    },
    // 验证码点击刷新方法
    // refreshCode() {
    //   this.identifyCode = "";
    //   // 4位验证码可以根据自己需要来定义验证码位数
    //   this.makeCode(this.identifyCodes, 4);
    // },
    // 随机数
    // randomNum(min, max) {
    //   max = max + 1;
    //   return Math.floor(Math.random() * (max - min) + min);
    // },
    // // 随机生成验证码字符串
    // makeCode(data, len) {
    //   for (let i = 0; i < len; i++) {
    //     this.identifyCode += data[this.randomNum(0, data.length - 1)];
    //   }
    // },
  },
  mounted() {
    //组件挂载完毕刷新验证码
    // this.refreshCode();
  },
};
</script>
<style lang="less" scoped>
// 背景图片
.login_container {
  background-image: url(@/assets/Image/loginBg.png);
  background-size: 100% 100%;
  height: 100%;
}

// 登录表单
.login_form {
  position: absolute;
  padding: 0 20px;
  box-sizing: border-box;
  left: 50%;
  top: 37%;
  width: 26%;
  height: 432px;
  // height: 44%;
  // height: 45%;
  opacity: 1;
  border-radius: 16px;
  // opacity: 0.8;
  /* 设置元素为半透明 */

  // 登录系统欢迎
  .WelcomeLgn {
    margin-top: 20px;
    width: 100%;
    text-align: center !important;
    height: 45px;
    opacity: 1;
    font-size: 1.7vw;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 44.8px;
    color: #fff;
    text-align: left;
    vertical-align: top;
  }

  // 登录验证块
  .borderDiv {
    margin: 11px auto 0px auto;
    width: 109px;
    height: 0px;
    opacity: 1;
    border-radius: 7px;
    border: 5px solid rgba(78, 78, 176, 1);
  }

  // 账号
  .UserName,
  .PassWord {
    margin-left: 15%;
    margin-top: 14px;
    width: 70%;
    opacity: 1;
    border-radius: 4px;
    background: rgba(204, 204, 204, 0.2);
    border: 2px solid rgba(153, 153, 153, 0.4);
  }

  // .yzm {
  //   margin-left: 15%;
  //   margin-top: 14px;
  //   width: 36%;
  //   opacity: 1;
  //   border-radius: 4px;
  //   background: rgba(204, 204, 204, 0.2);
  //   border: 2px solid rgba(153, 153, 153, 0.4);
  // }
}

// 登录按钮
.btns {
  margin-top: 5vh !important;
  margin-bottom: 10px !important;
  display: flex;
  width: 100%;
  margin-left: 170px;

  .btnsLogin {
    width: 10vw;
    height: 39px;
    opacity: 1;
    border-radius: 24px;
    background: rgb(49, 158, 221);
  }
}

// 验证码
.captcha-container {
  // width: 31%;
  overflow: hidden;
  position: absolute;
  left: 55%;
  top: 16px;
}

// input 错误信息提示
/deep/ .el-form-item__error {
  padding-left: 15% !important;
}
</style>
