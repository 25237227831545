import { render, staticRenderFns } from "./jsgl.vue?vue&type=template&id=1fe50c88&scoped=true"
import script from "./jsgl.vue?vue&type=script&lang=js"
export * from "./jsgl.vue?vue&type=script&lang=js"
import style0 from "./jsgl.vue?vue&type=style&index=0&id=1fe50c88&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fe50c88",
  null
  
)

export default component.exports