// 调用axios封装
import request from '@/axios/api'


// 获取table表格头部
export const getDataTop = data => {
    return request({
        url: '/tms/function/config',
        data,
        method: 'post'
    })
}
// 统一更新运输管理table数据接口

export const upLoadData = data => {
    return request({
        url: '/decl/change/ins/formdata',
        data,
        method: 'post'
    })
}
// 统一更新理赔管理table数据接口
export const upLoadlpData = data => {
    return request({
        url: '/claims/change/ins/formdata',
        data,
        method: 'post'
    })
}
// 调用详情内容
export const formDataBj = data => {
    return request({
        url: '/decl/show/formdata/byId',
        data,
        method: 'post'
    })
}
// 获取不同业务不同form查询项
export const getQueryForm = data => {
    return request({
        url: '/system/query/fields/config',
        data,
        method: 'post'
    })
}
//运输管理 提交OA接口
export const submitYsOA = data => {
    return request({
        url: '/decl/commit/formdata/oa',
        data,
        method: 'post'
    })
}
//理赔管理 提交OA接口
export const submitLpOA = data => {
    return request({
        url: '/claims/commit/formdata/oa',
        data,
        method: 'post'
    })
}


