// 调用axios封装
import request from '@/axios/api'


// 货运险条件设置表单数据
export const getTabledata = () => {
    return request({
        url: '/system/setting/freight/formdata/',
        method: 'get'
    })
}
// 获取货运险条件接口

export const getHytj = () => {
    return request({
        url: '/tms/freight/condition/dict',
        method: 'get'
    })
}
// 货运险条件设置

export const HytjSz = data => {
    return request({
        url: '/system/setting/freight/condition',
        data,
        method: 'post'
    })
} 
//删除货运险条件设置   
export const deleteSz = data => {
    return request({
        url: '/system/delete/freight/condition',
        data,
        method: 'post'
    })
} 


