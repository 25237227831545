// 调用axios封装
import request from '@/axios/api'


// blqktj第一个接口切换饼状图柱状图请求
export const UserData = data => {
    return request({
        url: '/system/setting/role',
        data,
        method: 'get'
    })
}
// 获取全部权限
export const AllQxData = data => {
    return request({
        url: '/tms/init/function/config',
        data,
        method: 'get'
    })
}

// 获取所有客户信息 
export const AllkhData = data => {
    return request({
        url: '/system/find/all/customers',
        data,
        method: 'get'
    })
}
// 添加角色 角色信息
export const addUserData = data => {
    return request({
        url: '/system/save/role',
        data,
        method: 'post'
    })
}

// 编辑角色信息
export const editUser = data => {
    return request({
        url: '/system/find/role/byId',
        data,
        method: 'post'
    })
}
// 保存编辑 角色信息
export const SaveUserData = data => {
    return request({
        url: '/system/update/role/byId',
        data,
        method: 'post'
    })
}
// 删除角色
export const DeleteUser = data => {
    return request({
        url: '/system/delete/role/byId',
        data,
        method: 'post'
    })
}


