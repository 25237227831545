// 调用axios封装
import request from '@/axios/api'


// 退出登录
export const UserLoginOut = data => {
    return request({
        url: '/tms/user/logout',
        data,
        method: 'post'
    })
}

// 登录
export const UserLogin = data => {
    return request({
        url: '/tms/user/login',
        data,
        method: 'post'
    })
}
