// 调用axios封装
import request from '@/axios/api'


// 获取oatable数据
export const getOaData = data => {
    return request({
        url: '/tms/oa',
        data,
        method: 'post'
    })
}
// 获取oa 审批表单
export const getOaSpData = data => {
    return request({
        url: '/tms/oa/approval',
        data,
        method: 'post',
    
    })
}
// 查看表单数据
export const tableFrom = data => {
    return request({
        url: '/tms/oa/query',
        data,
        method: 'post',
      
    })
}



