// 调用axios封装
import request from '@/axios/api'


// 获取商业险表单所有数据
export const commercialData= data => {
    return request({
        url: '/tms/cd/insurance/commercial',
        data,
        method: 'post'
    })
}
// 申报管理险表单数据
export const sbglClgzzrxData= data => {
    return request({
        url: '/decl/show/ins/formdata',
        data,
        method: 'post'
    })
}
// 理赔管理险表单数据
export const lpglClgzzrxData= data => {
    return request({
        url: '/claims/show/ins/formdata',
        data,
        method: 'post'
    })
}
// 获取交强险表单数据
export const sbglJqxData= data => {
    return request({
        url: '/tms/cd/insurance/compulsory',
        data,
        method: 'post'
    })
}
// 获取货运险表单所有数据
export const sbglHyxData= data => {
    return request({
        url: '/tms/sb/declaration_entry',
        data,
        method: 'post'
    })
}