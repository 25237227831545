<template>
  <div>
    <div
      style="
        border: 1px, solid, rgb(221, 221, 221);
        height: auto;
        background-color: rgb(245, 250, 254);
        margin-bottom: 10px;
        display: flex;
        justify-content: space-around;
        height: 56vh;
      "
    >
      <div>
        <div>
          <div
            style="
              background-color: rgba(39, 43, 48);
              height: 50px;
              border-radius: 2px;
              color: #fff;
              padding: 5px;
              line-height: 50px;
              width: 35vw;
            "
          >
            运输情况
            <el-date-picker
              v-model="value1"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <!-- <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="date" label="日期" width="180">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="180">
            </el-table-column>
            <el-table-column prop="address" label="地址"> </el-table-column>
          </el-table> -->
          <el-table
  style="width: 100%"
  :data="ysqkgetValues"
  :show-header="false"
  border
  :cell-style="cellStyle"
>
  <el-table-column
    v-for="(item, index) in ysqkgetHeaders"
    :key="index"
    :prop="item"
  >
  </el-table-column>
</el-table>
        </div>
        <div>
          <div
            style="
              background-color: rgba(39, 43, 48);
              height: 30px;
              border-radius: 2px;
              color: #fff;
              padding: 5px;
              line-height: 30px;
              text-align: center;
            "
          >
            企财险情况
          </div>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="date" label="物流公司" width="180">
            </el-table-column>
            <el-table-column prop="name" label="有效期起始日期" width="180">
            </el-table-column>
            <el-table-column prop="address" label="有效期截止日期"> </el-table-column>
          </el-table>
        </div>
      </div>
      <div>
        <div>
          <div
            style="
              background-color: rgba(39, 43, 48);
              height: 30px;
              border-radius: 2px;
              color: #fff;
              padding: 5px;
              line-height: 30px;
              text-align: center;
            "
          >
            预存管理情况
          </div>
          <el-table :data="ycgltableData" border style="width: 100%">
            <el-table-column v-for="(item, index) in ycglheaders" :key="index" :prop="item.prop" :label="item.label" width="180">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- table模块  ref表格数据-->
    <div
      style="
        border: 1px, solid, rgb(221, 221, 221);
        background-color: rgb(245, 250, 254);
        margin-bottom: 10px;
        display: flex;
        justify-content: space-around;
        height: 30vh;
      "
    >
      <div>
        <div>
          <div
            style="
              background-color: rgba(39, 43, 48);
              height: 50px;
              border-radius: 2px;
              color: #fff;
              padding: 5px;
              line-height: 50px;
              width: 35vw;
            "
          >
            雇主责任险情况
            <el-date-picker
              v-model="value1"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>

          <el-table
  style="width: 100%"
  :data="gzzrxgetValues"
  :show-header="false"
  border
  :cell-style="cellStyle"
>
  <el-table-column
    v-for="(item, index) in gzzrxgetHeaders"
    :key="index"
    :prop="item"
  >
  </el-table-column>
</el-table>
        </div>
      </div>
      <div>
        <div>
          <div
            style="
              background-color: rgba(39, 43, 48);
              height: 30px;
              border-radius: 2px;
              color: #fff;
              padding: 5px;
              line-height: 30px;
              text-align: center;
            "
          >
            车队情况
          </div>
          <el-table :data="cdqktableData" border style="width: 100%">
            <el-table-column v-for="(item, index) in cdqkheaders" :key="index" :prop="item.prop" :label="item.label" width="180">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      templateId: "1", // 这个就是页面的ref 用来判定需要哪一个模版
      value1: "",
      tableData: [
        {
          date: "极狐速运",
          name: "2016-03-21",
          address: "2024-04-07",
        },
      ],
      // 运输情况
      ysqkheaders: [
	      {
	        prop: 'date',
	        label: '在数车辆',
	      },
	      {
	        prop: 'name',
	        label: '在途运输车辆',
	      },
        {
	        prop: 'dateover',
	        label: '已完结车辆',
	      },
	      {
	        prop: 'namedateover',
	        label: '已完结运输车俩',
	      },
	    ],
	    ysqktableData: [
	      {
	        date: '11台',
	        name: '180台',
          dateover:'45台',
          namedateover:'23台'
	      },
	    ],
      // 预存管理情况
      ycglheaders: [
	      {
	        prop: 'date',
	        label: '险种',
	      },
	      {
	        prop: 'name',
	        label: '状态',
	      },
       
	    ],
	    ycgltableData: [
	      {
	        date: '交强险',
	        name: '正常',
      
	      },
        {
	        date: '商业险',
	        name: '正常',
      
	      },
        {
	        date: '货运险',
	        name: '正常',
      
	      },
        {
	        date: '员工雇主责任险',
	        name: '正常',
      
	      },
        {
	        date: '车辆雇主责任险',
	        name: '正常',
      
	      },
        {
	        date: '货运三者险',
	        name: '正常',
      
	      },
	    ],
      // 车队情况
      cdqkheaders: [
	      {
	        prop: 'date',
	        label: '险种',
	      },
	      {
	        prop: 'name',
	        label: '承保台次',
	      },
        {
	        prop: 'dateover',
	        label: '操作',
	      },
	    ],
	    cdqktableData: [
	      {
	        date: '交强险',
	        name: '180台',
          dateover:'——',
          
	      },
        {
	        date: '商业险',
	        name: '180台',
          dateover:'——',
          
	      },
        {
	        date: '即将到期',
	        name: '180台',
          dateover:'查看',
          
	      },
	    ],
      // 雇主责任险
      gzzrxheaders: [
	      {
	        prop: 'date',
	        label: '已投保',
	      },
	      {
	        prop: 'name',
	        label: '本年度共计',
	      },
	    ],
	    gzzrxtableData: [
	      {
	        date: '23人',
	        name: '110人',
	      },
	    ]
    };
  },
  methods: {
    cellStyle ({row, column, rowIndex, columnIndex}) {
      if(columnIndex===0) {
        return 'background: #F5F7FA; textAlign: center'
      }
      return 'textAlign: center'
    }
  },
  computed: {
              // 运输情况
  ysqkgetHeaders() {
    return this.ysqktableData.reduce((pre, cur, index) => pre.concat(`value${index}`), ['title'])
  },
  ysqkgetValues() {
    return this.ysqkheaders.map(item => {
      return this.ysqktableData.reduce((pre, cur, index) => Object.assign(pre, {['value' + index]: cur[item.prop]}), {'title': item.label,});
    });
  },
          // 雇主责任险
  gzzrxgetHeaders() {
    return this.gzzrxtableData.reduce((pre, cur, index) => pre.concat(`value${index}`), ['title'])
  },
  gzzrxgetValues() {
    return this.gzzrxheaders.map(item => {
      return this.gzzrxtableData.reduce((pre, cur, index) => Object.assign(pre, {['value' + index]: cur[item.prop]}), {'title': item.label,});
    });
  }
},

};
</script>
<style lang="less" scoped>
/deep/.el-date-editor--datetimerange.el-input__inner {
  width: 360px !important;
  height: 30px !important;
  line-height: 20px !important;
}
// /deep/.el-date-editor .el-range-separator {
//   line-height: 0px !important;
// }
/deep/.el-date-editor .el-range-separator {
  line-height: 20px !important;
}
/deep/.el-input__icon {
  height: auto !important;
}
</style>
