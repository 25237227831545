// 调用axios封装
import request from '@/axios/api'


// 获取新车货运险保费设置表单数据
export const newCardata = data => {
    return request({
        url: '/system/setting/hyxbf',
        data,
        method: 'post'
    })
}
// 设置新车货运险保费
export const newCarxg = data => {
    return request({
        url: '/system/setting/freightIns',
        data,
        method: 'post'
    })
}
// 获取二手车货运险保费设置表单数据
export const secoundCardata = data => {
    return request({
        url: '/system/setting/usedcars',
        data,
        method: 'post'
    })
}                           
// 设置二手车货运险保费
export const secoundCarxg = data => {
    return request({
        url: '/system/setting/usedcars/freight',
        data,
        method: 'post'
    })
}
// 删除货运险保费设置
export const deleteBfsz = data => {
    return request({
        url: '/system/delete/freightIns',
        data,
        method: 'post'
    })
}
// 下载货运险保费模板
export const downLoad = data => {
    return request({
        responseType: "blob",
        url: '/download/template',
        data,
        method: 'post'
    })
}
