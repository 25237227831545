import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
Vue.use(ElementUI);

Vue.config.productionTip = false

// 全局错误捕获
Vue.config.errorHandler = (err, vm, info) => {
  console.error('全局错误捕获:', err, info);
  vm.$message.error('系统错误，请联系管理员'); // 根据需要调整提示内容
};

// 配置请求的根路径
Vue.prototype.$http = axios
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
