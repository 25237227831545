<template>
  <div>
    <div v-show="!isBj">

      <!-- 查询栏 -->
      <div class="cxl">
        <el-form
          :inline="true"
          :model="formLabelAlign"
          class="demo-form-inline"
        >
          <el-form-item label="选择货运险条件" class="custom-form-item">
            <el-select v-model="formLabelAlign.logisticsCompany" placeholder="请选择货运险条件">
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.dictValue"
      :value="item.dictCode
">
    </el-option>
  </el-select>
          </el-form-item>

          <el-form-item class="cxl-form-btns">
            <el-button class="cxl-form-btn" @click="onSubmit">添加</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- table模块  ref表格数据-->
      <div style="margin-top: 20px">
        <el-table
          ref="multipleTable"
          :data="tableData"
          border
          style="width: 100%"
          :header-cell-style="{ background: 'rgb(245,250,254)', color: '#000' }"
        >
          <el-table-column
            align="center"
            header-align="center"
            label="条件"
            prop="conditionName"
          >
          </el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            label="正选条件值"
            prop="allowedValue"
          >
          
          <template slot-scope="scope">  
        <el-input  
          v-model="scope.row.allowedValue"  
          placeholder="请输入正选条件值"  
           :disabled="scope.row.notAllowedValue !== ''"
        ></el-input>  
      </template>  
          </el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            label="反选条件值"
            prop="notAllowedValue"
          >
          
          <template slot-scope="scope">  
        <el-input  
          v-model="scope.row.notAllowedValue"  
          placeholder="请输入反选条件值"  
          :disabled="scope.row.allowedValue !== ''"
        ></el-input>  
      </template>  
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="操作"
            width="150"
            style="display: flex; justify-content: left"
          >
            <template slot-scope="scope">
              <i
                class="el-icon-delete"
                @click="handleDelect(scope.$index, scope.row)"
                title="删除"
              ></i>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            label="状态"
            prop="isActive"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.isActive == 'true' ? '开启' : '关闭'"
                placement="top"
              >
                <el-switch
                  :value="scope.row.isActive == 'true' ? true : false"
                  @change="handleSwitchChange(scope.$index, scope.row, $event)"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页器 -->
        <div v-if="tableData.length" style="text-align: left; margin-top: 5px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 引入table top 组件
import yhglTableTop from "@/components/Public/Tabletop/yhglForm.vue";
// 引入table组件
import xtTable from "@/components/Public/Table/index.vue";
// 引入后端接口
import {getTabledata,getHytj,HytjSz,deleteSz} from '@/axios/Xtsz/Xtsz/hyxtjss.js'
import {
  UserData,
  OpenUserData,
  UpdataUserData,
  // OpenUserRoleData,
  SaveUserData,
  UserDataById,
  DeleteUserId,
} from "@/axios/Xtsz/Ywgl/yhgl.js"; //接口
import * as XLSX from "xlsx"; //导出类型
export default {
  components: {
    xtTable,
    yhglTableTop,
  },
  data() {
    return {
      templateId: "1", // 这个就是页面的ref 用来判定需要哪一个模版
      tableData: [], //表格数据
      form: {
        id: "",
        oldPassword: "",
        newPassword: "",
        company: "",
        phone: "",
        membersStatus: "",
        isDestroy: 0,
        password: "",
      },
      status: [], //请选择所属角色
      formInline: {
        rolename: "",
      }, //查询项
      isBj: false, //true是编辑 false不是编辑
      currentPage: 1,
      PageSize: 10,
      pageSizes: [10, 20, 30, 40],
      isAdd: false, //true是添加 false不是添加
      formLabelAlign:{
        logisticsCompany:''
      },
      options:[], //复选框数据
    };
  },
  methods: {
    // 导出xt-table中的table数据
    handleExport() {
      // 从TableComponent获取数据
      const tableData = this.$refs.tableComponent.getTableData();
      const tableTop = this.$refs.tableComponent.getTableTop()[0];
      const tableValue = this.$refs.tableComponent.getTableTop()[1];
      // 将数据转换为工作表
      const ws = XLSX.utils.aoa_to_sheet([
        tableTop,
        ...tableData.map((row) => {
          const values = tableValue.map((header) => {
            // 将 header 转换为小写（如果原对象的键名也是小写的话，这一步可以省略）
            // 但由于你的键名是大写和混合的，所以这里不转换
            // 直接使用原 header 作为键名来访问对象属性
            return row[header] || ""; // 如果属性不存在，则返回空字符串
          });
          return values;
        }),
      ]);
      // 创建一个工作簿并添加工作表
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // 导出xlsx文件
      XLSX.writeFile(wb, `表格数据_${this.templateId}.xlsx`);
    },

    // 添加用户操作
    tjyh() {
      // 清空form内容
      this.form = {};
      this.isBj = true;
      this.isAdd = true; //添加用户标识
    },
    // 取消编辑用户
    qxbj() {
      this.isBj = false;
      this.form = {}; // 清空表单数据
    },
    // 查询
    onQuery() {
      UserDataById({ rolename: this.formInline.rolename }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
        }
      });
    },
    handleSizeChange(val) {
      this.PageSize = val;
      this.$emit("PageSize", val);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.$emit("currentPage", val);
    },
    //初始化获取table
    queryUserData() {
      getTabledata().then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          console.log(res.data);
          // this.form = res.data;
        }
      });
    },
    // 初始获取查询项
    queryForm() {
      getHytj().then((res) => {
        if (res.code == 200) {
          this.options = res.data;
          console.log(res.data,'下拉选');
          
        }
      });
    },
    // 添加货运险条件
    onSubmit() {
      const selectedOption = this.options.find(option => option.dictCode === this.formLabelAlign.logisticsCompany);  
      if (selectedOption) {  
        console.log('选中的货运险条件:', selectedOption.dictValue);  
let containsSameDictValue = this.tableData.some(item => item.conditionName=== selectedOption.dictValue);  
// 已经添加了该货运险条件
if (containsSameDictValue==true) {
  this.$message.error('已添加该货运险条件;请勿重复添加');
  return;
}else{
  console.log(containsSameDictValue);
        // 你可以在这里处理selectedOption  
        const a={
          id: selectedOption.id,
  conditionType: selectedOption.dictCode,
  conditionName: selectedOption.dictValue,
  allowedValue: '',
  notAllowedValue:'',
  isActive: 'false'
        }
        console.log(a);
        
        HytjSz(a).then((res) => {
          console.log(res.code);
          this.queryUserData();
        });
}

      }  
    },
    // 删除
    handleDelect(index, rowData) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteSz({ id: rowData.id }).then((res) => {
            if (res.code == 200) {
              this.$message.success("删除成功");
              this.queryUserData(); // 刷新表格初始化
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 切换
    handleSwitchChange(index, row, value) {
      console.log(row.isActive);
      
      const isActiveBoolean = row.isActive === 'true';  
  row.isActive = isActiveBoolean ? 'false' : 'true';
      if (row.isActive === 'false') {  
        // 如果关闭，清空输入框值（这里直接修改editableAllowedValue）  
        row.allowedValue = '';  
        row.notAllowedValue = '';  
        // 或者，如果您希望保留原始值，可以不执行上述清空操作  
        HytjSz({
          id: row.id,
          conditionType: row.conditionType,
          conditionName: row.conditionName,
          allowedValue: row.allowedValue,
          notAllowedValue: row.notAllowedValue,
          isActive: row.isActive
        }).then((res) => {
          console.log(res.code)
          this.queryUserData();
        });
      } 
      if (row.isActive ==='true'){
        if (row.allowedValue==='' && row.notAllowedValue==='') {
          this.$message.error('请输入条件值值；再进行状态保存操作');
          row.isActive = 'false' //强制禁用【状态】
          return
        }else{
          HytjSz({
          id: row.id,
          conditionType: row.conditionType,
          conditionName: row.conditionName,
          allowedValue: row.allowedValue,
          notAllowedValue: row.notAllowedValue,
          isActive: row.isActive
        }).then((res) => {
          console.log(res.code)
          this.queryUserData();
        });
        }
      
      }
    },
  },
  mounted() {
    this.queryUserData();
    this.queryForm();
  },
};
</script>
<style lang="less" scoped>
// 下载栏
.xzl {
  border: 1px, solid, rgb(221, 221, 221);
  display: flex;

  // 上传+导入
  .xzl-div-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;

    // 按钮公共样式
    .xzl-div-btns {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(231, 102, 109);
      margin-left: 1vw;
    }

    .xzl-div-btns:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}

// 查询栏
.cxl {
  border: 1px solid rgb(221, 221, 221);
  position: relative;
  padding: 10px;
  padding-bottom: 0px;

  // input输入框 label
  /deep/ .custom-form-item .el-form-item__label {
    font-size: 12px !important;
    /* 修改字体大小 */
    width: 120px;
    /* 限制宽度 */
    overflow: hidden;
    /* 如果需要，可以添加溢出隐藏 */
    text-overflow: ellipsis;
    /* 文本溢出时显示省略号 */
    white-space: nowrap;
    /* 保持文本在一行内 */
  }

  // input输入框
  .cxl-input {
    width: 180px;
    font-size: 12px;
    height: 30px !important;
    /* 使用 !important 确保覆盖默认样式 */
  }

  // 修改input高度
  /deep/ .el-input__inner {
    height: 26px !important;
  }

  // 修改el-form-item行之间间距
  .el-form-item {
    margin-bottom: 10px !important;
  }

  // 查询项 按钮栏
  .cxl-form-btns {
    position: absolute;
    bottom: 0px;
    right: 30px;

    // 按钮
    .cxl-form-btn {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .cxl-form-btn:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}
/deep/.el-form-item {
  //   margin-bottom: 0px; //清空表格行间距margin
  // line-height:58px !important;
}

/deep/.el-form-item__label {
  display: block;
  height: 100%;
  //   border-top: 1px solid #eee !important;
  /* 举例，根据需要设置 */
  display: flex;
  align-items: center;

  /* 垂直居中内容 */
}

/deep/.el-form-item__content {
  //   border: 1px solid #eee;
}
</style>
