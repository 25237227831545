// 调用axios封装
import request from '@/axios/api'


// blqktj第一个接口切换饼状图柱状图请求
export const getSxf = data => {
    return request({
        url: '/system/setting/sxf',
        data,
        method: 'get'
    })
}
// 编辑角色信息
export const bjSxf = data => {
    return request({
        url: '/system/setting/free',
        data,
        method: 'post'
    })
}

